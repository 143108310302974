/* Define a altura do calendário */
.rdp {
  height: 300px;
  max-height: 100%;
  margin: 0 auto; 
  overflow-y: auto;
}

/* Ajusta o layout do cabeçalho do calendário */
.rdp-caption {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  gap: 10px;
  position: relative;
}

/* Botões de navegação (setas) */
.rdp-nav {
  display: flex;
  width: fit-content;
  left: -50%;
  transform: translateX(330%);
  justify-content: flex-end;
  position: absolute;
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
  z-index: 9;
}

/* Alinha as setas de navegação */
.rdp-nav:first-of-type {
  left: 0;
}

.rdp-nav:last-of-type {
  right: 0;
}

/* Ajusta o estilo do texto do título (mês e ano) */
.rdp-caption_label {
  margin-left: 5px;
  font-weight: bold;
  text-transform: capitalize;
}

/* Alinha os dias da semana */
.rdp-head_row {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  text-align: center;
  font-size: 0.9rem;
  color: #555;
  margin-bottom: 8px;
}

/* Alinha os dias do mês */
.rdp-days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  text-align: center;
}

/* Estilo para os dias */
.rdp-day {
  padding: 2px;
  font-size: 0.9rem;
  border-radius: 50%;
  cursor: pointer;
  width: fit-content;
  transition: background-color 0.2s, color 0.2s;
}

.rdp-day:hover {
  background-color: #f0f0f0;
}

/* Estilo para os dias selecionados */
.rdp-day_selected {
  background-color: #007bff;
  color: #fff;
  font-weight: bold;
}

/* Estilo para os dias em um intervalo selecionado */
.rdp-day_range {
  background-color: #cce5ff;
  color: #004085;
}
